import "../styles.css";

const Header = () => {
  return (
    <div className="header">
      <h1>Birdnest</h1>
    </div>
  );
};

export default Header;
